import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/ui/button';
import * as Styled from './styled';

const Banner = ({ title, desc, style }) => {
  return (
    <Styled.Banner style={style}>
      <h2 className="banner-title">{title || `Improve usability testing with LiveSession`}</h2>
      <p>{desc || 'Test all LiveSession features for 14 days, no credit card required.'}</p>
      <Button signUp secondary center sourceID="cta-banner">
        Sign up free
      </Button>
    </Styled.Banner>
  );
};

export default Banner;

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object]),
};

Banner.defaultProps = {
  desc: undefined,
  style: undefined,
};
