import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Banner = styled.div`
  width: 100%;
  padding: 64px 73px;
  text-align: center;
  background: #f8f8f8;
  border-radius: 4px;
  margin: 80px 0;
  ${media.mobile} {
    padding: 24px 16px;
    margin: 40px 0;
  }
  & .banner-title {
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 24px;
    margin-top: 0;
    font-weight: 800;
  }
  & p {
    margin-bottom: 40px;
  }
`;
